var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Distrito')}},[(_vm.view===false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.distritos,"append-to-body":"","calculate-position":_vm.withPopper,"multiple":true,"loading":_vm.loader.distrito,"label":"desc","item-text":"desc","item-value":"id"},on:{"input":_vm.getConcelhosByDistrito,"change":_vm.getConcelhosByDistrito},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var desc = ref.desc;
return [_vm._v(" "+_vm._s(desc)+" ")]}}],null,false,4124409017),model:{value:(_vm.localization.distrito),callback:function ($$v) {_vm.$set(_vm.localization, "distrito", $$v)},expression:"localization.distrito"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhum distrito'))+" ")])]):_vm._l((_vm.viewData.distritos),function(txt,index){return _c('p',{key:("distrito-" + index),staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(txt)+" ")])})],2)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Concelho')}},[(_vm.view===false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.concelhosGroup,"append-to-body":"","calculate-position":_vm.withPopperGroup,"multiple":true,"loading":_vm.loader.concelho,"label":"descFull","item-text":"descFull","item-value":"id","selectable":function (option) { return option.group === null; }},on:{"input":_vm.getFreguesiasByConcelho,"change":_vm.getFreguesiasByConcelho},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var group = ref.group;
var desc = ref.desc;
return [(group)?_c('div',{staticClass:"group"},[_vm._v(" "+_vm._s(_vm.$t('Concelhos de'))+" "+_vm._s(group)+" ")]):_vm._e(),_vm._v(" "+_vm._s(desc)+" ")]}}],null,false,2900020392),model:{value:(_vm.localization.concelho),callback:function ($$v) {_vm.$set(_vm.localization, "concelho", $$v)},expression:"localization.concelho"}},[(_vm.localization.distrito)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhum concelho'))+" ")]):_vm._e(),(!_vm.localization.distrito)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Escolha pelo menos um distrito'))+" ")]):_vm._e()]):_vm._l((_vm.viewData.concelhos),function(txt,index){return _c('p',{key:("concelho-" + index),staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(txt)+" ")])})],2)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Freguesia')}},[(_vm.view===false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.freguesiasGroup,"append-to-body":"","calculate-position":_vm.withPopperGroup,"multiple":true,"loading":_vm.loader.freguesia,"label":"descFull","item-text":"descFull","item-value":"id","selectable":function (option) { return option.group === null; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var group = ref.group;
var desc = ref.desc;
return [(group)?_c('div',{staticClass:"group"},[_vm._v(" "+_vm._s(_vm.$t('Freguesias de'))+" "+_vm._s(group)+" ")]):_vm._e(),_vm._v(" "+_vm._s(desc)+" ")]}}],null,false,3470915674),model:{value:(_vm.localization.freguesia),callback:function ($$v) {_vm.$set(_vm.localization, "freguesia", $$v)},expression:"localization.freguesia"}},[(_vm.localization.concelho)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhuma freguesia'))+" ")]):_vm._e(),(!_vm.localization.concelho)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Escolha pelo menos um concelho'))+" ")]):_vm._e()]):_vm._l((_vm.viewData.freguesias),function(txt,index){return _c('p',{key:("freguesia-" + index),staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(txt)+" ")])})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }